import Header from "../components/Header";
import { useEffect, useState } from "react";
import {
  calculateHoursBetween,
  calculateSum,
  getAllUsers,
} from "../firebase";
import { User, offseasonStart, seasonEnd, seasonStart } from "../data/Types";
import NavButtons from "../components/NavButtons";
import { Table } from "react-bootstrap";

export default () => {
  const [users, setUsers] = useState<User[]>([]);
  const [hoursState, setHoursState] = useState<string>("Total Hours");
  const [i, setI] = useState<number>(1);

  useEffect(() => {
    getAllUsers().then(allUsers => {
      allUsers.forEach(u => {
        u.meetings = new Map(Object.entries(u.meetings));
      });

      setUsers(allUsers);
    });
  }, []);

  const updateHoursText = () => {
    setI(i + 1);
    const states: string[] = ["Total Hours", "Season Hours", "Off-Season Hours", "Volunteer Hours"];
    setHoursState(states[i % states.length]);
  }

  const getFilteredHours = (user: User): number => {
    if (hoursState === "Total Hours") {
      return calculateHoursBetween(user, new Date(user.created.seconds), new Date()) + user.bonus;
    } else if (hoursState === "Season Hours") {
      return calculateHoursBetween(user, seasonStart, seasonEnd);
    } else if (hoursState === "Off-Season Hours") {
      return calculateHoursBetween(user, offseasonStart, seasonStart);
    } else if (hoursState === "Volunteer Hours") {
      return user.volunteer;
    }
    return 0;
  }

  const getRankEmoji = (rank: number) => {
    if (rank === 1) return " 👑";
    else if (rank === users.length) return " 😭";
    else if (rank === 2) return " 🥈";
    else if (rank === 3) return " 🥉";
    return "";
  }

  const getCustomEmoji = (user: string, rank: number) => {
    if (user === "Rumeth Pathirana") return " 💵";
    if (user === "Owen Horn") return " ✈️";
    if (user === "Victoria Velasquez" && rank === 1) return " 👎";
    if (user === "Jose Barrios") return " 🪫"
    if (user === "Can Tataker") return [" 💵", " 🔊", " 💕", " 😶‍🌫️", " 🔒"][Math.floor(Math.random() * 5)];
    if (user === "Tima Gezalov" || user === "Maaz Kattangere" || user === "Elijah Stewart" ||
      user === "Aniket Chakraborty" || user === "Ryan Nirmal" || user === "Davey Adams") return " 💻";

    return getRankEmoji(rank);
  }
  
  return (
    <>
      <Header />
      <div>
        <NavButtons pageName="Leaderboard"/>
      </div>

      <Table
        striped
        bordered
        hover
        style={{ marginTop: "1rem", width: (-0.0269637 * window.innerWidth + 100.289) + "%" }}
      >
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th style={{ color: "white", backgroundColor: "black", width: "10%" }}>Rank</th>
            <th style={{ color: "white", backgroundColor: "black", width: "30%" }}>Name</th>
            <th style={{ color: "white", backgroundColor: "black", width: "10%" }}><span className="text-button" onClick={() => updateHoursText()}>{hoursState}</span></th>
          </tr>
        </thead>
        <tbody>
          {users
            .sort((a, b) => getFilteredHours(b) - getFilteredHours(a))
            .map((user, index) => (
              <tr key={index} style={{ textAlign: "center" }}>
                <th className="hours-cell" style={{ color: "white", backgroundColor: "black" }}>{index + 1}</th>
                <th className="hours-cell" style={{ color: "white", backgroundColor: "black" }}>{user.name + getCustomEmoji(user.name, index + 1)}</th>
                <th className="hours-cell" style={{ color: "white", backgroundColor: "black" }}>{getFilteredHours(user)}</th>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </>
  )
}
