import { Dropdown, Button } from "react-bootstrap";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import {
  calculateHoursBetween,
  calculateSignedInTime,
  calculateSum,
  getAllUsers,
  getAttendanceRate,
  getRank,
  isSignedIn,
  signInOut,
} from "../firebase";
import { User, seasonEnd, seasonStart, today } from "../data/Types";
import NavButtons from "../components/NavButtons";
import AdminButton from "../components/AdminButton";

export default () => {
  const [users, setUsers] = useState<User[]>([]);
  const [user, setUser] = useState<User>();
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    getAllUsers().then(allUsers => {
      allUsers.forEach(u => {
        u.meetings = new Map(Object.entries(u.meetings));

        if (u.id === localStorage.getItem("id")) {
          setUser(u);
        }
      });

      setUsers(allUsers);
    });
  }, []);

  useEffect(() => {
    if (user !== undefined) {
      localStorage.setItem("id", user.id);
    }
  }, [user]);

  const getMessage = (user: User) => {
    if (user === undefined) return "Error";
    if (user.meetings.get(today()) === undefined) return "Sign In";
    
    const len: number | undefined = user.meetings.get(today())?.length;
    if (len === undefined) return "Error";
    return len % 2 === 0 ? "Sign In" : "Sign Out";
  }
  
  return (
    <>
      <Header />
      <div className="greeting">
        <h2>Hi</h2>
        <Dropdown className="dropdown-button">
          <Dropdown.Toggle variant="primary">
            {user ? user.name : "Select User"}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu">
            <input
              className="dropdown-search"
              autoFocus
              autoComplete="none"
              onChange={e => setSearchQuery(e.target.value)}
            ></input>
            {
              users
                .filter(user => user.name.toLowerCase().includes(searchQuery.toLowerCase()))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((user, index) => {
                  return (
                    <Dropdown.Item key={index} onClick={() => setUser(user)}>
                      {user.name}
                    </Dropdown.Item>
                  )
                }
              )
            }
          </Dropdown.Menu>
        </Dropdown>
        <h2>!</h2>
      </div>

      { user &&
        <>
          <div className="message">
            <b>Lets dive into your stats...</b>
          </div>

          <div className="statistics">
            <p className="stat">Total Hours: {calculateHoursBetween(user, new Date(user.created.seconds), new Date()) + user.bonus}</p>
            <p className="stat">Season Hours: {calculateHoursBetween(user, seasonStart, seasonEnd)}</p>
            <p className="stat">Meetings Attended: {user.meetings.size} ({Math.round(getAttendanceRate(user, users, new Date(user.created.seconds * 1000), new Date()))}%)</p>
            <p className="stat">Volunteer Hours: {user.volunteer}</p>
            <p className="stat">Hours Rank (Out of {users.length}): #{getRank(user, users)}</p>
          </div>

          { isSignedIn(user) &&
            <>
              <br />
              <div className="statistics">
                <p className="stat">Signed In Time: {calculateSignedInTime(user)}</p>
              </div>
            </>
          }

          <div className="sign-button">
            <Button style={{width: "100%", height: "100%"}} onClick={() => { signInOut(user).then(u => setUser(structuredClone(u)))}}>{user ? getMessage(user) : "Select User"}</Button>
          </div>
        </>
      }

      <div className="footer">
        <AdminButton />
        <NavButtons pageName="Home"/>
      </div>
    </>
  )
}