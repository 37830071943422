import { useState } from "react";
import "../index.css";
import { FBUser, User } from "../data/Types";
import { Button, Form, Modal } from "react-bootstrap";
import { updateUser } from "../firebase";

interface Props {
	user: User;
	onSave: Function;
}

export default (props: Props) => {
	const [show, setShow] = useState<boolean>(false);
	const [name, setName] = useState<string>(props.user.name);
	const [graduation, setGraduation] = useState<string>(props.user.graduation);
	const [volunteer, setVolunteer] = useState<number>(props.user.volunteer);
	const [bonus, setBonus] = useState<number>(props.user.bonus);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleSave = () => {
		const user = props.user as FBUser;
		user.name = name;
		user.graduation = graduation;
    user.volunteer = volunteer;
		user.bonus = bonus;

		user.meetings = Object.fromEntries(Object(user.meetings));

		updateUser(user);

		user.meetings = Object.entries(user.meetings)

		props.onSave();
		handleClose();
	};

	return (
		<>
			<Button className="action" onClick={handleShow}>Edit</Button>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Edit {props.user.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p style={{color: "black"}}>User ID: {props.user.id}</p>
					<Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
								defaultValue={props.user.name}
								onChange={(e) => setName(e.target.value)}
                autoFocus
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Graduation</Form.Label>
              <Form.Control
                type="name"
								defaultValue={props.user.graduation}
								onChange={(e) => setGraduation(e.target.value)}
                autoFocus
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Volunteer</Form.Label>
              <Form.Control
                type="name"
								defaultValue={props.user.volunteer}
								onChange={(e) => setVolunteer(Number(e.target.value))}
                autoFocus
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Bonus Hours</Form.Label>
              <Form.Control
                type="name"
								defaultValue={props.user.bonus}
								onChange={(e) => setBonus(Number(e.target.value))}
                autoFocus
              />
            </Form.Group>
          </Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>Close</Button>
					<Button variant="primary" onClick={handleSave}>Save changes</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
